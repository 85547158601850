<template>
  <div class="team_invited">
    <Navbar />
    <div class="container">
      <div class="main-area">
        <div class="team_invited__content" v-if="!state.preloadMain">
          <h3>{{state.teamInfo.name}}からの招待</h3>
          <div class="team_invited__content__form">
            <TeamDetailContent
              :isInvited="true"
              :source="state.teamInfo"
              :categories="state.categories"
              :gotoUserInfo="gotoUserInfo"
              :gotoUserAchievement="gotoUserAchievement"
            />
          </div>
          <div class="btn_group">
            <button
              :disabled="state.loadingAccept || state.loadingReject"
              class="btn-md btn-light-gray"
              type="button"
              @click="rejectJoinTeam"
            >
              辞退する
            </button>
            <button
              :disabled="state.loadingAccept || state.loadingReject"
              class="btn-md btn-blue shadow"
              type="button"
              @click="acceptJoinTeam"
            >
              チームに参加する
            </button>
          </div>
        </div>
        <TeamDetailPreloader v-else />
      </div>
    </div>
    <Alert v-if="state.showPopupAlert" @close="closePopupAlert" :content="state.titleAlert" />
    <RejectTeamPopup
      v-if="state.showPopupReject"
      @close="closePopupConfirm"
      :title="state.titleReject"
      :content="state.contentReject"
      :okButtonText="state.okButtonReject"
      :type="state.confirmType"
      :loading="state.confirmType == 'reject' ? state.loadingReject : state.loadingAccept"
      @accept="acceptConfirm"
    />
    <div class="white_screen" v-if="state.checkToken"></div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/user/team/team-invited/TeamInvited.ts">
</script>

<style lang="scss" src="@/presentation/views/user/team/team-invited/TeamInvited.scss" scoped>
</style>
